<template lang="html">
  <div id="home-page">

    <section class="banner-section">
      <swiper class="swiper desktop" :options="bannersOptions">
        <swiper-slide v-for="(b, bhInx) in banners" :key="'bhInx-'+bhInx">
          <div class="placed-backg box" v-bind:style="{ backgroundImage: 'url('+b.imageUrl+')' }">
            <img src="public/images/pages/home/banner.png">
          </div>
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>

      <swiper class="swiper mobile" :options="bannersOptions">
        <swiper-slide v-for="(b, bhmInx) in banners" :key="'bhmInx-'+bhmInx">
          <div class="placed-backg box" v-bind:style="{ backgroundImage: 'url('+b.imageMobUrl+')' }">
            <img src="public/images/pages/home/banner-m.png">
          </div>
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </section>

    <section class="container oversized-container products-section">
      <div class="d-block text-center">
        <h2 class="title-s1"><span>Productos</span></h2>
      </div>

      <swiper class="swiper" :options="productsOptions">
        <swiper-slide v-for="(p, phInx) in products" :key="'phInx-'+phInx">
          <div class="col-12 px-0 col-products-sample-1">
            <router-link class="box-link" to="">
              <div class="box-image" v-bind:style="{ backgroundImage: 'url('+p.imageUrl+')' }">
                <img src="public/images/pages/products/product.png">
              </div>

              <div class="box-info">
                <h5 class="name">{{ p.name }}</h5>

                <div class="descr">
                  <p>{{ p.descr }}</p>
                </div>

                <p class="text-center">
                  <a class="btn-order">Ordenar</a>
                </p>
              </div>
            </router-link>
          </div>
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </section>

    <section class="container-fluid how-section">
      <div class="row">
        <div class="col-12 text-center">
          <h2 class="title-s1"><span>Cómo funciona</span></h2>
        </div>

        <div class="col-lg-4 col-info">
          <div class="placed-backg box">
            <div class="inside">
              <p class="mb-4">
                <img src="public/images/pages/home/how-1.png" alt="Destapa">
              </p>

              <h4>Destapa</h4>

              <div class="box-descr">
                <p>Esto es solo un texto de ejemplo para el contenido que irá en este bloque con una mini descripción</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-info">
          <div class="placed-backg box">
            <div class="inside">
              <p class="mb-4">
                <img src="public/images/pages/home/how-2.png" alt="Inserta">
              </p>

              <h4>Inserta</h4>

              <div class="box-descr">
                <p>Esto es solo un texto de ejemplo para el contenido que irá en este bloque con una mini descripción</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-info">
          <div class="placed-backg box">
            <div class="inside">
              <p class="mb-4">
                <img src="public/images/pages/home/how-3.png" alt="Disfruta">
              </p>

              <h4>Disfruta</h4>

              <div class="box-descr">
                <p>Esto es solo un texto de ejemplo para el contenido que irá en este bloque con una mini descripción</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container med-container benefits-section">
      <div class="d-block mb-4 text-center">
        <h2 class="title-s1"><span>Beneficios</span></h2>
      </div>

      <div class="box-benefits">
        <div class="col-3 col-benefit" v-bind:class="{ 'active' : benefitOpt == 1 }" @click="benefitOpt = 1">
          <h4 class="name">Higiénico</h4>

          <div class="line"></div>
        </div>

        <div class="col-3 col-benefit" v-bind:class="{ 'active' : benefitOpt == 2 }" @click="benefitOpt = 2">
          <h4 class="name">Práctico</h4>

          <div class="line"></div>
        </div>

        <div class="col-3 col-benefit" v-bind:class="{ 'active' : benefitOpt == 3 }" @click="benefitOpt = 3">
          <h4 class="name">Espacio</h4>

          <div class="line"></div>
        </div>

        <div class="col-3 col-benefit" v-bind:class="{ 'active' : benefitOpt == 4 }" @click="benefitOpt = 4">
          <h4 class="name">Ecológico</h4>

          <div class="line"></div>
        </div>
      </div>

      <div class="box-benefit-info">
        <img class="w-100" src="public/images/pages/home/benefit-1.jpg" v-if="benefitOpt == 1">
        <img class="w-100" src="public/images/pages/home/benefit-2.jpg" v-if="benefitOpt == 2">
        <img class="w-100" src="public/images/pages/home/benefit-3.jpg" v-if="benefitOpt == 3">
        <img class="w-100" src="public/images/pages/home/benefit-4.jpg" v-if="benefitOpt == 4">
      </div>
    </section>

    <section class="container med-container ecological-section">
      <div class="row justify-content-center">
        <div class="col-xl-11">
          <div class="row">
            <div class="col-lg-5 col-info">
              <h2 class="title">
                <span class="style-1">Materiales</span>
                <span class="style-2">Ecológicos</span>
              </h2>

              <p class="p-1">
                Nuestros procesos y materiales plásticos contribuyen a reutilizarlos de forma rápida y con la buenas prácticas de reciclado.
              </p>
              <p>
                <router-link class="btn-more" to="/tecnologia">Saber más</router-link>
              </p>
            </div>

            <div class="col-lg-7 col-image">
              <img src="public/images/pages/home/woman.png">
            </div>
          </div>
        </div>
      </div>
    </section>

    <banners2-component></banners2-component>
  </div>
</template>

<script>
export default {
  data() {
    return {
      banners: [
        { imageUrl: 'public/images/pages/home/banner-1.jpg', imageMobUrl: 'public/images/pages/home/banner-1-m.jpg' },
        { imageUrl: 'public/images/pages/home/banner-2.jpg', imageMobUrl: 'public/images/pages/home/banner-2-m.jpg' },
        { imageUrl: 'public/images/pages/home/banner-3.jpg', imageMobUrl: 'public/images/pages/home/banner-3-m.jpg' },
        { imageUrl: 'public/images/pages/home/banner-4.jpg', imageMobUrl: 'public/images/pages/home/banner-4-m.jpg' },
      ],

      products: [
        { imageUrl: 'public/images/pages/products/product-1.jpg', name: 'Garrafón Aqua', descr: 'Garrafón de plástico para 20 lts de agua.' },
        { imageUrl: 'public/images/pages/products/product-2.jpg', name: 'Aqua Punch', descr: 'Contenedor de cerámica para 10 lts de agua.' },
        { imageUrl: 'public/images/pages/products/product-3.jpg', name: 'Jarra de agua', descr: 'Jarra para 5 lts, presentación en 3 diferentes colores.' },
        { imageUrl: 'public/images/pages/products/product-4.jpg', name: 'Mueble aqua', descr: 'Mueble con espacio para 5 paquetes de 10 lts (2 Bolsas de 5 lts c/u)' },
      ],

      benefitOpt: 1,

      // == Carousel options ==
      bannersOptions: {
        loop: true,
        speed: 700,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        autoplay: {
          delay: 4000,
          disableOnInteraction: false
        },
      },

      productsOptions: {
        slidesPerView: 4,
        spaceBetween: 40,
        loop: true,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        breakpoints: {
          1200: {
            slidesPerView: 4,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 40
          },
          576: {
            slidesPerView: 2,
            spaceBetween: 30
          },
          1: {
            slidesPerView: 1
          },
        }
      }
      // == ==
    }
  }
}
</script>
