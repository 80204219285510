<template lang="html">
  <div id="products-results-page">

    <section class="container oversized-container products-section">
      <div class="row">
        <div class="col-12 col-title">
          <h2 class="title-s1"><span>Productos</span></h2>
        </div>

        <div class="col-sm-6 col-lg-4 col-xl-3 col-products-sample-1" v-for="(p, pInx) in products" :key="'pInx-'+pInx">
          <router-link class="box-link" to="/producto/1">
            <div class="box-image" v-bind:style="{ backgroundImage: 'url('+p.imageUrl+')' }">
              <img src="public/images/pages/products/product.png">
            </div>

            <div class="box-info">
              <h5 class="name">{{ p.name }}</h5>

              <div class="descr">
                <p>{{ p.descr }}</p>
              </div>

              <p class="text-center">
                <a class="btn-order">Ordenar</a>
              </p>
            </div>
          </router-link>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      products: [
        { imageUrl: 'public/images/pages/products/product-1.jpg', name: 'Garrafón Aqua', descr: 'Garrafón de plástico para 20 lts de agua.' },
        { imageUrl: 'public/images/pages/products/product-2.jpg', name: 'Aqua Punch', descr: 'Contenedor de cerámica para 10 lts de agua.' },
        { imageUrl: 'public/images/pages/products/product-3.jpg', name: 'Jarra de agua', descr: 'Jarra para 5 lts, presentación en 3 diferentes colores.' },
        { imageUrl: 'public/images/pages/products/product-4.jpg', name: 'Mueble aqua', descr: 'Mueble con espacio para 5 paquetes de 10 lts (2 Bolsas de 5 lts c/u)' },

        { imageUrl: 'public/images/pages/products/product-4.jpg', name: 'Mueble aqua', descr: 'Mueble con espacio para 5 paquetes de 10 lts (2 Bolsas de 5 lts c/u)' },
        { imageUrl: 'public/images/pages/products/product-3.jpg', name: 'Jarra de agua', descr: 'Jarra para 5 lts, presentación en 3 diferentes colores.' },
        { imageUrl: 'public/images/pages/products/product-2.jpg', name: 'Aqua Punch', descr: 'Contenedor de cerámica para 10 lts de agua.' },
        { imageUrl: 'public/images/pages/products/product-1.jpg', name: 'Garrafón Aqua', descr: 'Garrafón de plástico para 20 lts de agua.' },
        { imageUrl: 'public/images/pages/products/product-1.jpg', name: 'Garrafón Aqua', descr: 'Garrafón de plástico para 20 lts de agua.' },
        { imageUrl: 'public/images/pages/products/product-2.jpg', name: 'Aqua Punch', descr: 'Contenedor de cerámica para 10 lts de agua.' },
        { imageUrl: 'public/images/pages/products/product-3.jpg', name: 'Jarra de agua', descr: 'Jarra para 5 lts, presentación en 3 diferentes colores.' },
        { imageUrl: 'public/images/pages/products/product-4.jpg', name: 'Mueble aqua', descr: 'Mueble con espacio para 5 paquetes de 10 lts (2 Bolsas de 5 lts c/u)' },
      ],
    }
  }
}
</script>
