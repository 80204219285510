<template lang="html">
  <div class="container" id="text-page">
    <h1 class="page-title txt-black">Aviso de privacidad</h1>

    <p class="mb-2">
      Según lo Previsto en la “Ley Federal de Protección de Datos Personales”, Purificadora Costa Rica S.A. de C.V. declara ser una empresa legalmente constituida bajo las leyes mexicanas, con domicilio en Calle Pichón numero exterior 1500, Colonia Morelos, C.P. 44910, Guadalajara Jalisco, México.
    </p>

    <br />
    <!-- <h5>DATOS PERSONALES</h5> -->
    <p>
      Purificadora Costa Rica S.A. de C.V. como responsable del manejo de sus datos personales, hace de su conocimiento que la información de nuestros clientes es tratada de forma estrictamente confidencial por lo que al proporcionar sus datos tales como:
    </p>
    <ol>
      <li>Nombre Completo.</li>
      <li>Domicilio.</li>
      <li>Teléfonos de Hogar, Oficina y Celular.</li>
      <li>Correo Electrónico.</li>
      <li>Para Empresas:
        <ul>
          <li>Registro Federal de Contribuyentes (RFC).</li>
        </ul>
      </li>
    </ol>

    <p>
      Estos serán utilizados única y exclusivamente con la siguiente finalidad:
    </p>
    <ol>
      <li>Información de contacto.</li>
      <li>Información y Prestación de Servicios.</li>
      <li>Actualización de la Base de Datos.</li>
    </ol>

    <p>
      Para prevenir el acceso no autorizado a sus datos personales y con el fin de asegurar que la información sea utilizada para los fines establecidos en este aviso de privacidad, hemos establecido diversos procedimientos tales como evitar el uso o divulgación no autorizados de sus datos a terceros. De la misma forma, hacemos de su conocimiento que sus datos personales pueden ser Transmitidos para ser tratados por personas ajenas a esta empresa para uso exclusivo de lo ya mencionado.
    </p>

    <br />
    <p>
      Todos sus datos personales son tratados bajo las legislaciones aplicables y vigentes en la República Mexicana regidos por el Instituto Federal de Acceso a la Información y Protección de Datos (ifai), por eso hacemos de su conocimiento que usted tiene en todo momento los derechos (ARCO) de acceder, rectificar, modificar, cancelar u oponerse al manejo que le damos a sus datos personales; derecho que podrá hacer valer a través del Área encargada del tratamiento de dicha información al Teléfono (33) 3813-8124 o por medio de correo electrónico: at.clientes@aguacostarica.com.mx
    </p>

    <br />
    <p>
      El presente aviso de privacidad podrá ser modificado por Purificadora Costa Rica S.A. de C.V., todas las modificaciones serán oportunamente informadas a través de nuestro sitio Web, Redes sociales, o cualquier otro medio de comunicación que Purificadora Costa Rica S.A. de C.V. determine para tal efecto.
    </p>
  </div>
</template>

<script>
export default {
}
</script>
