<template lang="html">
  <section class="banner-s2-component">
    <router-link to="/productos">
      <img class="w-100 desktop" src="public/images/pages/home/banner-s2-1.jpg">
      <img class="w-100 mobile" src="public/images/pages/home/banner-s2-1-m.jpg">
    </router-link>
  </section>
</template>

<script>
export default {
}
</script>
