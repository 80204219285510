<template lang="html">
  <div class="placed-backg" id="about-us-page">

    <div class="container content-section">
      <div class="box-title">
        <h2 class="title-s1"><span>Empresa</span></h2>
      </div>

      <div class="box-text">
        <h5 class="text-center title">22 Años de experiencia</h5>
        <p>
          Purificadora Costa Rica nace con la intención de ofrecer un sistema diferente diseñado en pro de tu beneficio y el de todas las amas de casa que como tu cada día son más prácticas y dinámicas.
        </p>

        <h5 class="mt-4 text-center title">AQUAPunch</h5>
        <p>
          Es un sistema higienico, innovador y practico que facilita el abastecimiento de agua purificada mediante ligeras bolsas de plástico desechables de material reciclable para no contaminar.
        </p>
      </div>
    </div>

  </div>
</template>

<script>
export default {
}
</script>
