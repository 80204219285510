<template lang="html">
  <div id="technology-page">

    <section class="flex-center-xy placed-backg banner-section"  v-bind:style="{ backgroundImage: 'url(public/images/pages/technology/bg.jpg)' }">
      <div class="box">
        <h2 class="title-s1"><span>Tecnología</span></h2>
      </div>
    </section>

    <section class="container content-section">
      <div class="row">
        <div class="col-12 col-title">
          <h2 class="sec-title">Contamos con <strong>5 pasos</strong> en nuestro proceso de purificación</h2>
        </div>

        <div class="col-12 col-tech">
          <div class="box-tech">
            <div class="box-image">
              <img class="empty" src="public/images/shared/empty.png">

              <div class="inside">
                <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url(public/images/pages/technology/tech-1.png)' }"></div>
              </div>
            </div>

            <div class="box-info">
              <h4 class="title">Clorado 2PPM</h4>
              <p class="descr">Reposo de agua cruda por 24 horas</p>
            </div>
          </div>
        </div>

        <div class="col-12 col-tech r">
          <div class="box-tech">
            <div class="box-image">
              <img class="empty" src="public/images/shared/empty.png">

              <div class="inside">
                <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url(public/images/pages/technology/tech-2.png)' }"></div>
              </div>
            </div>

            <div class="box-info">
              <h4 class="title">Filtros de carbón activado</h4>
              <p class="descr">Eliminando Olor y sabor del Clorado</p>
            </div>
          </div>
        </div>

        <div class="col-12 col-tech">
          <div class="box-tech">
            <div class="box-image">
              <img class="empty" src="public/images/shared/empty.png">

              <div class="inside">
                <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url(public/images/pages/technology/tech-3.png)' }"></div>
              </div>
            </div>

            <div class="box-info">
              <h4 class="title">Zeolita</h4>
              <p class="descr">Contiene los sólidos disueltos del agua</p>
            </div>
          </div>
        </div>

        <div class="col-12 col-tech r">
          <div class="box-tech">
            <div class="box-image">
              <img class="empty" src="public/images/shared/empty.png">

              <div class="inside">
                <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url(public/images/pages/technology/tech-4.png)' }"></div>
              </div>
            </div>

            <div class="box-info">
              <h4 class="title">Osmosis inversa</h4>
              <p class="descr">Para controlar los minerales y ofrecerte un agua baja en sales</p>
            </div>
          </div>
        </div>

        <div class="col-12 col-tech">
          <div class="box-tech">
            <div class="box-image">
              <img class="empty" src="public/images/shared/empty.png">

              <div class="inside">
                <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url(public/images/pages/technology/tech-5.png)' }"></div>
              </div>
            </div>

            <div class="box-info">
              <h4 class="title">Iones de plata para garantizar la pureza del agua</h4>
              <p class="descr">Para Garantizar la calidad del agua</p>
            </div>
          </div>
        </div>

      </div>
    </section>

    <banners2-component></banners2-component>

  </div>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
</style>
