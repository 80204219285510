<template lang="html">
  <footer id="footer">
		<div class="container oversized-container">
			<div class="row row-n1">
				<div class="col-lg col-footer">
					<router-link class="btn-logo" to="/">
						<img src="public/images/logo.svg" alt="Agua Costa Rica">
					</router-link>

					<p class="mt-3 txt-info">
						Calle Pichón #1500 Col. Morelos,<br />
						Guadalajara, Jalisco. C.P. 44910<br />
						<strong>Tels: (33) 3812 8124 y (33) 3120 1889</strong>
					</p>
				</div>

				<div class="col-lg-2 col-footer">
					<h5 class="title">Costa Rica</h5>

					<p class="mb-1 mb-sm-2">
						<router-link class="link-s1" to="/productos">Productos</router-link>
					</p>
					<p class="mb-1 mb-sm-2">
						<router-link class="link-s1" to="/tecnologia">Tecnología</router-link>
					</p>
					<p class="mb-1 mb-sm-2">
						<router-link class="link-s1" to="/empresa">Empresa</router-link>
					</p>
					<p>
						<router-link class="link-s1" to="/contacto">Contacto</router-link>
					</p>
				</div>

				<div class="col-lg-3 col-xl col-footer">
					<h5 class="title">Síguenos</h5>

					<div class="row justify-content-center justify-content-lg-start mx-0">
						<a class="t-150 btn-network-s2" href="#"><i class="fab fa-facebook"></i></a>
						<a class="t-150 btn-network" href="#"><i class="fab fa-instagram"></i></a>
						<a class="t-150 btn-network" href="#"><i class="fab fa-whatsapp"></i></a>
					</div>
				</div>

				<div class="col-lg col-footer">
					<p class="mb-4">
						<a class="btn-call" href="tel:3338128124">
							<span>Pedidos por teléfono</span>
							<strong>33 3812 8124</strong>
						</a>
					</p>

					<p>
						<router-link class="btn-order" to="/ordenar">
							<i class="far fa-truck"></i> Haz tu pedido en línea
						</router-link>
					</p>
				</div>
			</div>

			<div class="row row-n2">
				<div class="col-12">
					<hr class="line" />
				</div>

				<div class="col-md-6 col-footer">
					<router-link class="link-s2" to="/aviso-de-privacidad">Aviso de privacidad</router-link>
				</div>

				<div class="col-md-6 col-footer">
					<p class="copy"><strong>Costa Rica Marca Registrada 2022.</strong> Todos los derechos reservados.</p>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
export default {
}
</script>
