<template lang="html">
  <div id="order-page">

    <section class="container oversized-container contact-form">
      <b-form class="row justify-content-center" @submit="onSubmit">
        <div class="col-12 col-title">
          <h2 class="title-s1"><span>Ordenar</span></h2>
        </div>

        <div class="col-lg-5 mb-3 mb-lg-0 col-image">
          <img src="public/images/pages/order/truck.jpg">
        </div>

        <div class="col-lg-7 col-form">
          <div class="row">
            <div class="mb-3 col-12">
              <p class="note">Pedido solo disponible en la ciudad de Guadalajara</p>
            </div>

            <div class="col-lg-6">
              <b-form-group class="form-group-s1"
                label="Nombre:">
                <b-form-input
                  v-model="form.nombre"
                  type="text"
                  placeholder="Escriba su nombre y apellido"
                  required
                ></b-form-input>
              </b-form-group>
            </div>

            <div class="col-lg-6">
              <b-form-group class="form-group-s1"
                label="Correo electrónico:">
                <b-form-input
                  v-model="form.email"
                  type="email"
                  placeholder="ejemplo@email.com"
                  required
                ></b-form-input>
              </b-form-group>
            </div>

            <div class="col-lg-6">
              <b-form-group class="form-group-s1"
                label="Teléfono:">
                <b-form-input
                  v-model="form.tel"
                  type="text"
                  minlength="10"
                  maxlength="10"
                  placeholder="Escriba su teléfono"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group class="form-group-s1"
                label="Calle y número:">
                <b-form-textarea
                  v-model="form.direccion"
                  placeholder="Escriba su calle y número"
                  rows="2"
                  max-rows="2"
                  required
                ></b-form-textarea>
              </b-form-group>

              <b-form-group class="form-group-s1"
                label="Colonia:">
                <b-form-textarea
                  v-model="form.colonia"
                  placeholder="Escriba su colonia"
                  rows="2"
                  max-rows="2"
                  required
                ></b-form-textarea>
              </b-form-group>
            </div>

            <div class="col-lg-6">
              <b-form-group class="form-group-s1"
                label="Notas:">
                <b-form-textarea class="text-notas"
                  v-model="form.notas"
                  placeholder="Agregue una nota"
                  rows="9"
                  max-rows="9"
                  required
                ></b-form-textarea>
              </b-form-group>
            </div>

            <div class="col-12 text-right pt-2">
              <b-button type="submit" class="btn-s1 btn-lg"><i class="fal fa-truck"></i> Realizar pedido</b-button>
            </div>
          </div>
        </div>
      </b-form>
    </section>

    <banners2-component></banners2-component>

  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        nombre: null,
        email: null,
        tel: null,
        direccion: null,
        colonia: null,
        notas: null,
      }
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
    },
  }
}
</script>
