<template lang="html">
  <div id="contact-page">

    <section class="container contact-form">
      <b-form class="row justify-content-center" @submit="onSubmit">
        <div class="col-12 col-title">
          <h2 class="title-s1"><span>Contacto</span></h2>
        </div>

        <div class="col-lg-7">
          <b-form-group class="form-group-s1"
            label="Nombre:">
            <b-form-input
              v-model="form.name"
              type="text"
              placeholder="Escriba su nombre y apellido"
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-lg-7">
          <b-form-group class="form-group-s1"
            label="Correo electrónico:">
            <b-form-input
              v-model="form.email"
              type="email"
              placeholder="ejemplo@email.com"
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-lg-7">
          <b-form-group class="form-group-s1"
            label="Teléfono:">
            <b-form-input
              v-model="form.phone"
              type="text"
              minlength="10"
              maxlength="10"
              placeholder="Escriba su teléfono"
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-lg-7">
          <b-form-group class="form-group-s1"
            label="Mensaje:">
            <b-form-textarea
              v-model="form.msg"
              placeholder="Escriba un mensaje"
              rows="4"
              max-rows="4"
              required
            ></b-form-textarea>
          </b-form-group>
        </div>

        <div class="col-lg-7 text-right pt-2">
          <b-button type="submit" class="btn-s1 btn-lg"><i class="fal fa-envelope"></i> Enviar formulario</b-button>
        </div>
      </b-form>
    </section>

    <banners2-component></banners2-component>

  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: null,
        email: null,
        phone: null,
        msg: null,
      }
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
    },
  }
}
</script>
