<template lang="html">
  <header id="header">
    <div class="header-content">
      <b-navbar toggleable="lg" type="light" variant="light">
        <div class="container oversized-container">
          <b-navbar-brand to="/">
            <img src="public/images/logo.svg" alt="Agua Costa Rica">
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse">
            <i class="far fa-bars"></i>
          </b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>
              <b-nav-item class="simple-item" to="/productos">Productos</b-nav-item>
              <b-nav-item class="simple-item" to="/tecnologia">Tecnología</b-nav-item>
              <b-nav-item class="simple-item" to="/empresa">Empresa</b-nav-item>
              <b-nav-item class="simple-item" to="/contacto">Contacto</b-nav-item>

              <b-nav-item-dropdown class="simple-item dropdown-account" right>
                <template #button-content>
                  <span><i class="fas fa-user"></i> Mi cuenta</span>
                </template>
                <b-dropdown-item to="/login">Iniciar sesión</b-dropdown-item>
                <b-dropdown-item to="/registrarse">Registrarse</b-dropdown-item>
              </b-nav-item-dropdown>

              <b-nav-item class="simple-item cart" to="/cart">
                <span class="icon"><i class="fas fa-shopping-cart"></i><i class="num">0</i></span>
                <span class="d-lg-none ml-2">Mi carrito</span>
              </b-nav-item>
            </b-navbar-nav>

            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
              <li class="nav-item nav-phone">
                <a class="nav-link">
                  <span>Pedidos por teléfono</span>
                  <strong>33 3812 8124</strong>
                </a>
              </li>

              <b-nav-item class="nav-order" to="/ordenar"><i class="far fa-truck"></i> Haz tu pedido en línea</b-nav-item>
            </b-navbar-nav>
          </b-collapse>
        </div>
      </b-navbar>
    </div>
  </header>
</template>

<script>
export default {
}
</script>
